import {
  BASE_PAYMENT_SERVICE_URL,
  BASE_SCHOOL_SERVICE_URL,
  BASE_UAA_SERVICE_URL,
  BILL_API
} from '../constants/api'
import axios from 'axios'

export const addOrderProduct = async (data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/product/add-item`
  const response = await axios.post(url, data)
  return response?.data?.data || {}
}

export const updateOrderProduct = async (data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/product/item`
  const response = await axios.put(url, data)
  return response?.data?.data || {}
}

export const updateOrderService = async (data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/service/item`
  const response = await axios.put(url, data)
  return response?.data?.data || {}
}

export const addOrderService = async (data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/service/add-item`
  const response = await axios.post(url, data)
  return response?.data?.data || {}
}

export const getOrderById = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}`
  const response = await axios.get(url)
  return response?.data?.data || {}
}

export const getFamilyChildren = async (familyId) => {
  const url = `${BASE_UAA_SERVICE_URL}/family/${familyId}`
  const response = await axios.get(url)
  return response?.data?.data?.studentDTOS || []
}

export const getChildGroups = async (studentId) => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/student/${studentId}/groups`
  const response = await axios.get(url)
  return response?.data?.data || []
}
export const saveOrder = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}/save`
  const response = await axios.post(url)
  return response?.data?.data || []
}
export const deleteOrderDetail = async (orderDetailId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order-detail/${orderDetailId}`
  const response = await axios.delete(url)
  return response?.data?.data || []
}
export const filterProductsByNameOrBarcode = async (params) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/products/find/by-name-or-barcode`
  const response = await axios.post(url, params)
  return response?.data?.data?.content || []
}
export const getDepositPiggyBank = async (schoolId, familyId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/deposit/school/${schoolId}/family/${familyId}`
  const response = await axios.get(url)
  return response?.data?.data?.amount
}
export const getOrderStatuses = async () => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/statuses`
  const response = await axios.get(url)
  return response?.data?.data || []
}

export const getOrderResults = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/check/${orderId}`
  const response = await axios.get(url)
  return response?.data?.data || {}
}

export const spentFromDeposit = async (orderId, amount) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/deposit?amount=${amount}&orderId=${orderId}`
  const response = await axios.post(url)
  return response?.data?.data || {}
}

export const undoSpentFromDeposit = async (paymentId, orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/${paymentId}`
  const response = await axios.put(url, {
    amount: 0,
    orderId,
    paymentType: 'DEPOSIT'
  })
  return response?.data?.data || {}
}
export const writeOffFromCash = async (orderId, amount) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/order/${orderId}/cash`
  const response = await axios.post(url, { cash: parseFloat(amount) })
  return response?.data?.data || {}
}
export const writeOffFromCard = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/order/${orderId}/card`
  const response = await axios.post(url, { orderId: parseInt(orderId) })
  return response?.data?.data || {}
}
export const writeOffFromCardAndCash = async (orderId, card, cash) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/order/${orderId}/card-and-cash`

  const response = await axios.post(url, {
    card: !isNaN(parseInt(card)) ? parseFloat(card) : 0,
    cash: !isNaN(parseInt(cash)) ? parseFloat(cash) : 0
  })
  return response?.data?.data || {}
}
export const saveOnlinePay = async (orderId, data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/order/${orderId}/online`
  const response = await axios.post(url,
    { orderId: parseInt(orderId), ...data })
  return response?.data?.data || {}
}
export const getStems = async (familyId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/stem/balances/family/${familyId}`
  const response = await axios.get(url)
  return response?.data?.data || {}
}

export const applyStems = async (orderId, studentId, amount) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/stem?amount=${amount}&orderId=${orderId}&studentId=${studentId}`
  const response = await axios.post(url)
  return response?.data?.data || {}
}
export const getOrderBill = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/bill/order/${orderId}`
  const response = await axios.get(url)
  return response?.data?.data?.billPrint || {}
}
export const createPdf = async (content) => {
  const url = `${BILL_API}/`
  const response = await axios.post(url, {
    content,
    type: 'PDF'
  },
  { responseType: 'blob' })
  return response || false
}

export const createPdfFull = async (content) => {
  const url = `${BILL_API}/extra`
  const response = await axios.post(url, {
    content,
    type: 'PDF'
  },
  { responseType: 'blob' })
  return response || false
}

export const getPaymentInfo = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/order/${orderId}/payment-info`
  const response = await axios.get(url)
  return response?.data?.data || {}
}
export const sendEmailInvoice = async (orderId, data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/payment/order/${orderId}/invoice`
  const response = await axios.post(url, { email: data.email })
  return response?.data?.data || {}
}
export const applyPromoCode = async (orderId, promoCode) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}/promo-code/apply-general?promoCode=${promoCode}`
  const response = await axios.post(url)
  return response
}
export const deletePromoCode = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}/promo-code`
  const response = await axios.delete(url)
  return response
}

export const recalcOrderSuppliers = async (orderId, data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}/recalc-suppliers`
  const response = await axios.post(url, data)
  return response?.data?.data || []
}
export const archiveOrder = async (orderId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}/archive`
  const response = await axios.post(url, { orderId })
  return response?.data?.data || false
}

export const getDepositData = async (familyId) => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/family/${familyId}/students/lessons-remain`
  const response = await axios.get(url)
  return response?.data?.data || []
}
export const transferToDeposit = async (data) => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/education-groups/cancel-subscription`
  return axios(
    {
      method: 'post',
      url,
      headers: { 'content-type': 'application/json' },
      withCredentials: true,
      data
    })
}

export const deleteOrder = async orderId => {
  return axios.delete(`${BASE_PAYMENT_SERVICE_URL}/api/order/${orderId}`,
    {
      headers: { 'content-type': 'application/json' },
      withCredentials: true
    })
}
